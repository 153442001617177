import React from "react"
import { graphql } from "gatsby"
import Title from "../../components/title"
import Layout from "../../components/layout"
import StyledHero from "../../components/styledHero"
import styles from "../../css/offer.module.css"
import SEO from "../../components/seo"

const Knooing = ({ data, location }) => {
  return (
    <Layout location={location.pathname}>
      <SEO title="Leistungsangebot Knooing" />
      <StyledHero img={data.knooingBcg.childImageSharp.fluid} />
      <section className={styles.offer}>
        <Title title="Leistungsangebot" subtitle="Knooing" />
        <div className={styles.offerCenter}>
          <header>
            <a
              href="https://knooing.de/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className={styles.partnerLogo}
                src="/knooing.png"
                alt="knooing Logo"
              />
            </a>
            <h4>Pro Bono Leistungsangebot knooing GmbH</h4>
          </header>
          <article className={styles.offerText}>
            <h4 className={styles.primary}>Für die Anbieterseite</h4>
            <h5>WIR UNTERSTÜTZEN IHREN SALES</h5>
            <p>
              Wir matchen Ihre Lösungen automatisch mit passenden Projekten als
              zusätzlicher Sales Channel.
            </p>
            <h5>WIR MATCHEN IHRE LÖSUNG</h5>
            <p>
              Wir schlagen Ihre Lösungen Anwendern vor, die passende Projekte
              haben und direkt mit der Umsetzung loslegen können.
            </p>
            <h5>WIR GEBEN IHNEN FEEDBACK</h5>
            <p>
              Wir leiten Ihnen das Feedback von Anwendern zu Ihren Lösungen
              weiter. So können Sie sich an Marktnachfragen einfach und schnell
              anpassen.
            </p>
            <h5>WIR SPAREN IHNEN KOSTEN UND ZEIT</h5>
            <p>
              Legen Sie einfach ihr Profil und Lösungsportfolio an und werden
              Sie gematched!
            </p>
          </article>
          <article className={styles.offerText}>
            <h4 className={styles.primary}>Für die Anwenderseite</h4>
            <h5>WIR DEFNIEREN IHRE ANFORDERUNGEN</h5>
            <p>
              Wir finden mithilfe unserer Knowledge Base heraus, welche Ziele
              und Spezifikationen Sie für Ihr Projekt benötigen.
            </p>
            <h5>WIR FINDEN PASSENDE LÖSUNGEN</h5>
            <p>
              Unsere KI Plattform gleicht Ihre Anforderungen mit möglichen
              Lösungen ab, um die bestpassenden Matches zu finden.
            </p>
            <h5>WIR VERGLEICHEN IHRE MATCHES</h5>
            <p>
              Wir zeigen die Stärken und Unterschiede der verschiedenen Lösungen
              auf, um Ihnen ein optimales Technologie Benchmarking zu
              ermöglichen.
            </p>
            <h5>WIR HELFEN BEI DER HAVE-OR-BUY ENTSCHEIDUNG</h5>
          </article>
          <footer>
            <a
              href="mailto:info@knooing.de"
              target="_blank"
              rel="noopener noreferrer"
              className="btn-primary"
            >
              Angebot anfordern
            </a>
          </footer>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query {
    knooingBcg: file(relativePath: { eq: "showroomBcg.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default Knooing
